#cc-main {    
    /** Change button primary color navy **/
    --cc-btn-primary-bg: #293455;
    --cc-btn-primary-border-color: #293455;
    --cc-btn-primary-hover-bg: #041E41;
    --cc-btn-primary-hover-border-color: #041E41;

    /** Change button secondary appearance **/
    --cc-btn-secondary-bg: #ffffff;
    --cc-btn-secondary-color: #293455;
    --cc-btn-secondary-border-color: #293455;
    --cc-btn-secondary-hover-bg: #f2f2f2;
    --cc-btn-secondary-hover-color: #041E41;
    --cc-btn-secondary-hover-border-color: #041E41;

    /** Also make toggles the same color as the button **/
    --cc-toggle-on-bg: var(--cc-btn-primary-bg);

    /** Make the buttons a bit rounder **/
    --cc-btn-border-radius: 8px;
}